import React from 'react'
import { Page } from '../components/utils'
import Layout, { extractData, THEMES } from '../components/layout'
import Section, { Subtitle } from '../components/section';
import { graphql } from 'gatsby';
import { Cinema } from '../model/model';
import { ThemeProvider } from 'styled-components';

interface PageProps {
  cinemas: Cinema[]
}
const PrivacyPolicyPage: React.SFC<PageProps> = ({cinemas}) => (
<Layout showBackButton={true} cinemas={cinemas} title="Informativa Privacy">
  <Page>
    <Section title="Privacy Policy">
      <p>
        Le seguenti norme sulla privacy - da intendersi rese anche quale informativa ai sensi dell’art. 13
        del Regolamento europeo 2016/679 “General Data Protection Regulation” – hanno lo scopo di descrivere le procedure
        di raccolta e utilizzo dei dati personali attraverso questo sito internet e sono rivolte agli utenti e ai fruitori
        dei servizi web di “Multiplex delle Stelle” e "Cinema Odeon 6", accessibili per via telematica a partire dall'indirizzo:
      </p>
      <p>www.multiplexdellestelle.it e www.odeon6.it</p>
      <p>corrispondente alla pagina iniziale del sito ufficiale della società Publiodeon Srl.</p>


      <Subtitle>Titolare del trattamento dati e DPO</Subtitle>

      <p>
        Titolare del trattamento dei dati è Publiodeon Srl. (di seguito il “Titolare” o la “Società”).
      </p>

      <p>
        Il Titolare ha designato Data Protection Officer (di seguito “DPO”) Pietro Ferretti,
        contattabile, per tutte le questioni relative al trattamento dei tuoi dati personali
        e all’esercizio dei tuoi diritti previsti dal GDPR, al seguente indirizzo di posta elettronica: amministrazione@multiplexdellestelle.it.
      </p>

      <Subtitle>
        Oggetto e tipologia del trattamento dati effettuato
      </Subtitle>

      <p>
        Il Titolare tratta i dati personali, esclusivamente di natura non sensibile, comunicati dall’interessato tramite il sito internet in fase di:
      </p>

      <ul>
        <li>Acquisto biglietto</li>
        <li>Contattaci</li>
        <li>Chatbot</li>
      </ul>

      <p>
        compilando gli appositi form e rilasciando i consensi al trattamento dati personali specificatamente richiesti.
      </p>

      <p>
        Oltre a questi dati, liberamente immessi dall’utente sul sito, potrebbero essere trattati anche dati di navigazione, raccolti a seguito dell’utilizzo dei nostri servizi da parte dell’utente e, in particolare:
      </p>

      <li>informazioni sul dispositivo con cui l’utente si connette</li>

      <li>informazioni specifiche del dispositivo (ad esempio il tipo di dispositivo, informazioni sulla rete mobile)</li>

      <li>Informazioni sui log. Durante l’utilizzo da parte dell’utente dei nostri servizi o la visualizzazione di contenuti forniti dal Titolare, potremmo raccogliere e memorizzare automaticamente alcune informazioni nei log del server. Queste informazioni potrebbero comprendere: dati sulla modalità di utilizzo del nostro servizio, come le query di ricerca; informazioni sui dati della connessione a internet quali indirizzo IP e tutti i dati ad esso collegati; informazioni sugli eventi del dispositivo quali attività di sistema, impostazioni hardware, tipo di browser e lingua, data e ora delle richieste e degli URL di riferimento; cookie che potrebbero identificare in modo univoco il browser o l’account dell’utente</li>

      <p>
        Noi e i nostri partner potremmo utilizzare diverse tecnologie per raccogliere e memorizzare informazioni quando viene visitato il Sito e/o utilizzato un servizio della Società che potrebbero prevedere l’invio di uno o più cookie o identificatori anonimi al dispositivo dell’utente. Leggi la Cookie policy.
      </p>

      <Subtitle>Finalità del trattamento</Subtitle>
      <p>
        I dati di navigazione saranno utilizzati al fine di ricavare informazioni statistiche anonime sull’uso del sito
        e per controllarne il corretto funzionamento. Potranno essere utilizzati per l’accertamento di responsabilità da reato,
        in caso di illeciti ai danni del sito web.
      </p>
      <p>
        Gli altri dati personali, volontariamente forniti al Titolare, saranno trattati – anche senza l’espresso consenso dell’interessato
        ed in conformità all’art. 6 lett. b, c ed f del GDPR (basi giuridiche del trattamento) – per le seguenti finalità:
      </p>
      <ul>
        <li>riscontrare le richieste avanzate dall’interessato</li>
        <li>inviare comunicazioni di servizio, anche con riferimento a modifiche di termini contrattuali, contenuti, condizioni e politiche adottate</li>
        <li>adempiere agli obblighi derivanti dalla legge, da regolamenti, dalla normativa comunitaria o da un ordine dell’Autorità</li>
        <li>prevenire o scoprire attività fraudolente o abusive nei confronti del sito web</li>
        <li>esercitare ogni altro diritto del Titolare, come il diritto di difesa in giudizio, nonché ogni altro trattamento compatibile con le predette finalità</li>
      </ul>

      <p>
        I medesimi dati saranno invece trattati, solo previo libero e specifico consenso dell’interessato,
        per finalità diverse illustrate all’interno delle informative sul trattamento dei dati personali.
      </p>

      <Subtitle>Durata del trattamento</Subtitle>

      <p>
        I dati personali saranno trattati per il tempo necessario a conseguire gli scopi per i quali sono stati raccolti. Si evidenzia in particolare che i dati di navigazione verranno cancellati entro 2 anni dalla loro elaborazione.
      </p>

      <p>
        In ogni caso è fatto salvo un periodo di conservazione superiore, ove richiesto da norme di legge o regolamento.
      </p>

      <Subtitle>Comunicazione e trasferimento dei dati</Subtitle>

      <p>
        I dati potranno essere comunicati a soggetti pubblici e privati, persone fisiche e/o giuridiche
        (studi di consulenza legale, amministrativa e fiscale, eventuali società informatiche ed altri)
        nei cui confronti la comunicazione sia necessaria per il perseguimento di finalità contrattuali,
        amministrative, contabili, nonché per garantire agli interessati l’utilizzo e la fruizione del sito web.
      </p>

      <p>
        I dati potranno essere comunicati altresì ad altri soggetti, quando la comunicazione sia prevista o imposta dalla legge.
      </p>

      <p>
        I dati personali saranno trattati all’interno del territorio dell’Unione Europea.
        Qualora per questioni di natura tecnica e/o operativa si renda necessario avvalersi di soggetti ubicati al di fuori dell'Unione Europea,
        si garantisce sin d’ora che il trasferimento a tali soggetti, limitatamente allo svolgimento di specifiche attività di Trattamento,
        sarà effettuato in conformità a quanto previsto dal GDPR.
      </p>

      <Subtitle>Diritti degli interessati</Subtitle>

      <p>
        Ai sensi degli artt. 13, 15-22 del GDPR, l’interessato ha il diritto, in particolare:
      </p>

      <ul>
        <li>di ottenere la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano</li>
        <li>di ottenere l’accesso ai dati e alle seguenti informazioni (finalità del trattamento, categorie di dati personali, destinatari e/o categorie di destinatari, periodo di conservazione)</li>
        <li>di ottenere la rettifica o integrazione dei dati personali inesatti che lo riguardano</li>
        <li>di ottenere la cancellazione dei dati personali che lo riguardano nei casi previsti dall’art. 17 GDPR</li>
        <li>di ottenere che i dati personali che lo riguardano siano solo conservati senza che di essi sia fatto altro uso nei seguenti casi previsti dall’art. 18 GDPR</li>
        <li>di ricevere in un formato di uso comune, leggibile da dispositivo automatico e interoperabile, i dati personali che lo riguardano trattati con mezzi automatizzati, se essi sono trattati in forza di contratto o sulla base del suo consenso</li>
      </ul>

      <p>
        Per qualsiasi richiesta o comunicazione, ovvero per esercitare i Suoi diritti, può contattare il Titolare del trattamento
        inviando una e-mail: amministrazione@multiplexdellestelle.it.
      </p>

      <Subtitle>Diritti degli interessati</Subtitle>

      <p>
        Il Titolare si riserva la facoltà di aggiornare in qualsiasi momento la presente informativa.
        Si consiglia, pertanto, di procedere a periodiche verifiche, al fine di essere aggiornati sulla privacy policy della Società.
        In caso di modifiche sostanziali alla politica, verrà pubblicato un avviso sul Sito, insieme alla Politica sulla privacy aggiornata.
      </p>
    </Section>
  </Page>
</Layout>
)


const StaticPage: React.SFC<any> = ( query ) => {
  const { cinemas, mainCinemaId } = extractData(query)

  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <PrivacyPolicyPage cinemas={cinemas} />
    </ThemeProvider>
  )
}

export default StaticPage

export const query = graphql`
query PrivacyPageQuery {
  ...alldata
}
`