import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router';
import { StyledComponentAware } from './utils';


const Container = styled.section`
  flex-direction: column;
  margin: 0.5rem 0 0.5rem 0;
`

export const Title = styled.h2`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.highlight};
  text-transform: uppercase;
  margin-bottom: 0.5em;
`

export const Subtitle = styled.h3`
  font-size: 1.1rem;
  color: ${props => props.theme.colors.highlight};
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  padding-top: 5px;
  margin-bottom: 0.3rem;
`

interface SectionProps {
  title?: string
  renderTitle?: () => JSX.Element | string
  linkTo?: string
}

export const Section: React.SFC<SectionProps & StyledComponentAware> = ({title, renderTitle, linkTo, className, children}) => {
  const displayTitle = renderTitle == undefined
    ? title == null
      ? null
      : <Title>{title}</Title>
    : renderTitle()
  return (
    <Container className={className}>
      { linkTo == null
        ? displayTitle
        : <Link to={linkTo}>{displayTitle}</Link>
      }
      {children}
    </Container>
  )
}

export const SubSection: React.SFC<SectionProps & StyledComponentAware> = ({title, renderTitle, linkTo, className, children}) => {
  const displayTitle = renderTitle == undefined
    ? title == null
      ? null
      : <Subtitle>{title}</Subtitle>
    : renderTitle()
  return (
    <Container className={className}>
      { linkTo == null
        ? displayTitle
        : <Link to={linkTo}>{displayTitle}</Link>
      }
      {children}
    </Container>
  )
}

export default Section